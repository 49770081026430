import React, { useEffect, useState } from 'react';
import Block from "../components/Block";
import helper from "../helper";

function Abilities() {
  const [abilities, setAbilities] = useState([]);
  const [abilityTypes, setAbilityTypes] = useState([]);
  const [videoError, setVideoError] = useState({});

  useEffect(() => {
    setAbilities(document.config.abilities);
    setAbilityTypes(document.config.ability_types);
  }, []);

  const getItemByName = (abilityName) => {
    const abilityFullName = 'ability_' + abilityName;
    return document.craftItems.find(item => item.itemName === abilityFullName);
  };

  const handleVideoError = (abilityId) => {
    setVideoError(prev => ({ ...prev, [abilityId]: true }));
  };

  return (
    <div className="content">
      <div className="wrapper">
        <div className="flex">
          <Block>
            <div className="abilities">
              {abilities && abilities.map((ability) => (
                <div key={ability.abilityId} className="ability">
                  <div className="ability-name">
                    <span className="ability-image-wrapper">
                      {/*'data:image/png;base64,' + */}
                      {/*<img src={ability.imageUri} alt={ability.displayName}/>*/}
                      <span
                        className="item"
                        style={helper.getItemIconStyle(getItemByName(ability.abilityId))}
                      />
                    </span>
                    {ability.displayName}
                  </div>
                  <div className="ability-demo">
                    <video
                      src={`/ability_demo/${ability.abilityId}.mp4`}
                      controls={false}
                      muted={true}
                      loop={true}
                      playsInline={true}
                      autoPlay={true}
                      onError={() => handleVideoError(ability.abilityId)}
                    />
                    {videoError[ability.abilityId] &&
                      <div className="coming-soon">
                        <span className="coming-soon-text">Coming soon<span>.</span><span>.</span><span>.</span></span>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>
          </Block>
        </div>
      </div>
    </div>
  );
}

export default Abilities;
